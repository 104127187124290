const baseState = {
	accounts: [],
	personnel: [],
	console: [],
	logs: []
}

const data = (state = {...baseState}, action) => {
	switch (action.type) {
		case 'UPDATE_ACCOUNTS':
			return {
				...state,
				accounts: action.accounts
			}
		case 'UPDATE_PERSONNEL':
			return {
				...state,
				personnel: action.personnel
			}
		case 'UPDATE_PAYROLL_TOTALS':
			return {
				...state,
				payrollTotals: action.totals
			}
		case 'REPLACE_DATA':
			return {
				...state,
				[action.path]: [ ...action.data ]
			}
		case 'OUTPUT_TO_CONSOLE':
			return {
				...state,
				console: [action.string, ...state.console]
			}
		case 'PUSH_LOG':
			let logs = [action.log, ...state.logs]
			if (logs.length > 100) logs.pop()
			return {
				...state,
				logs: logs
			}
		default:
			return state
	}
}

export default data
