import React from 'react'
import { useState, Fragment, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useSetNavBarTitle } from '../../hooks/nav'
import { ObjectId } from 'bson'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextFieldBlock from '../ui/TextFieldBlock'
import Button from '../ui/button'

import { upsertDoc } from '../../actions/data'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1)
	},
	heading: {
		fontSize: theme.typography.pxToRem(17),
		fontWeight: theme.typography.fontWeightRegular
	}
}))

function InventoryItem({ match }) {
	const itemId = match.params.itemId
	const history = useHistory()
	const classes = useStyles()
	const dispatch = useDispatch()
	const item = useSelector((state) => {
		const items = state.data['inventory/items']
		let selectedItem = undefined
		if(itemId !== 'new') {
			const itemOId = ObjectId(itemId)
			for(let item of items) {
				if(item._id.equals(itemOId)) selectedItem = item
			}
		}
		return selectedItem
	})
	const restaurants = useSelector((state) => state.data.restaurants)
	const [ state, setState ] = useState(item? item : {
		name: '',
		restaurants: []
	})
	const navBarTitle = item? item.name : 'New Item'
	useSetNavBarTitle(navBarTitle)

	const onSaveClick = () => {
		dispatch(upsertDoc('inventory_items', state))
		history.goBack()
	}

	const onChange = (e, name, value) => {
		setState(state => {
			let nextState = {
				...state,
				[e.target.name]: e.target.value
			}
			return nextState
		})
	}

	const onMongoChipChange = (key, value) => {
		setState(state => {
			let nextState = {
				...state,
				[key]: value
			}
			return nextState
		})
	}

	return (
		<div className={classes.root}>
				<Paper style={{padding: 15, margin: 20}} elevation={1}>
					<TextFieldBlock
						state={state}
						layout={{
							blocks: [{
								heading: 'General',
								fields: [{
									label: 'Name',
									type: 'text',
									name: 'name'
								},{
									label: 'Restaurants',
									type: 'mongo-chip',
									name: 'restaurants',
									options: restaurants,
									getOptionLabel: (item) => item.name,
									onChange: onMongoChipChange
								}]
							}]
						}}
						editable={true}
						onChange={onChange}
					/>
					<Button onClick={onSaveClick}>Save</Button>
				</Paper>
		</div>
	)
}

export default InventoryItem
