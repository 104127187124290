import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
//import Fab from '@material-ui/core/Fab'
//import AddIcon from '@material-ui/icons/AddRounded'
//import { useHistory } from 'react-router'
import { useSetNavBarTitle } from '../hooks/nav'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(3),
		right: theme.spacing(3)
	}
}))

function Unemployment ({ payrollTotals }) {
	useSetNavBarTitle('Certification Hours')
	const classes = useStyles()
	//const history = useHistory()
	if (!payrollTotals) return <div>Loading...</div>

	function handleAccountClick(event, row) {
		//history.push('/app/accounts' + row.path)
	}

	let totalsByDay = {}
	let totalsByDayArray = []
	for(let total of payrollTotals) {
		let startDate = new Date(total.startDate)
		var start = new Date(2020, 0, 0);
		var diff = (startDate - start) + ((start.getTimezoneOffset() - startDate.getTimezoneOffset()) * 60 * 1000);
		var oneDay = 1000 * 60 * 60 * 24;
		var dayOfYear = Math.floor(diff / oneDay);
		let totalsByPeriodDays = total.totalsByDay.totalsByDay
		for(let i =0; i < 14; i++) {
			totalsByDay[dayOfYear + i] = {
				periodStartDate: startDate,
				totalHours: totalsByPeriodDays[i].totalHours,
				grossEarnings: totalsByPeriodDays[i].grossEarnings,
				tip: totalsByPeriodDays[i].tip
			}
			totalsByDayArray.push( {
				periodStartDate: startDate,
				totalHours: totalsByPeriodDays[i].totalHours,
				grossEarnings: totalsByPeriodDays[i].grossEarnings,
				tip: totalsByPeriodDays[i].tip
			})
		}
	}
	let certStartDate = new Date(2020, 7, 9, 0, 0, 0, 0)
	let certStartDay = 222
	let certEndDay = 604

	let certWeeks = []
	do {
		let certWeek = {
			startDay: certStartDay,
			startDate: new Date(certStartDate.getTime()),
			endDate: new Date(certStartDate.getTime() + 7 * 24 * 60 * 60 * 1000 - 1), 
			totalHours: 0.00,
			grossEarnings: 0.00,
			tip: 0.00
		}
		for(let i = 0; i < 7; i++) {
			if(totalsByDay.hasOwnProperty(certStartDay + i)) {
				certWeek.totalHours += totalsByDay[certStartDay + i].totalHours
				certWeek.grossEarnings += totalsByDay[certStartDay + i].grossEarnings
				certWeek.tip += totalsByDay[certStartDay + i].tip
			}
		}
		certWeeks.push(certWeek)
		certStartDay += 7
		certStartDate.setDate(certStartDate.getDate() + 7) 
	} while (certStartDay + 6 < certEndDay)

	if(payrollTotals.length > 0) {
		return (
			<div className={classes.root}>
				<Table className={classes.table} props={{ stickyHeader: true }}>
					<TableHead>
						<TableRow>
							<TableCell>Start Date</TableCell>
							<TableCell>End Date</TableCell>
							<TableCell>Total Hours</TableCell>
							<TableCell>Gross Earnings</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{certWeeks.map(week => (
							<TableRow
								hover
								roll="checkbox"
								onClick={event => handleAccountClick(event, week)}
								key={week.startDay}
							>
									<TableCell>{week.startDate.toDateString()}</TableCell>
									<TableCell>{week.endDate.toDateString()}</TableCell>
									<TableCell>{week.totalHours.toFixed(2)}</TableCell>
									<TableCell>${week.grossEarnings.toFixed(2)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		)

	} else {
		return (
			<div className={classes.root}>
				<Table className={classes.table} props={{ stickyHeader: true }}>
					<TableHead>
						<TableRow>
							<TableCell>Account</TableCell>
							<TableCell>Balance</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					</TableBody>
				</Table>
			</div>
		)

	}
}

const mapStateToProps = state => ({
	payrollTotals: state.data.unemployement
})

export default connect(mapStateToProps)(Unemployment)
