import React from 'react'
import { useSetNavBarTitle } from '../../hooks/nav'
import Button from '@material-ui/core/Button'
import OAuthClient from 'intuit-oauth'

function QuickbooksOAuth() {
	useSetNavBarTitle('Quickbooks OAuth')

	function onClick() {
		let oauthClient = new OAuthClient({
			clientId: 'ABGP05cm2P8bgjUM2w2CTUJs1UomfQi5dEE62z8V4iUA2YbHws',
			clientSecret: 'k55zuJjikh1WHumw1BRJVcl7bBQq5x0Q0f6KATPe',
			environment: 'production',
			redirectUri: 'https://dashboard.nccrestaurants.com/callbacks/quickbooks/oauth'
		})
		const authUri = oauthClient.authorizeUri({
			scope: [OAuthClient.scopes.Accounting, OAuthClient.scopes.OpenId, OAuthClient.scopes.Payments],
			state: 'test-state'
		})
		window.location.href = authUri
	}

	return (
			<Button
				variant="contained"
				color="primary"	
				component="span"
				onClick={onClick}
				//startIcon={<CloudUploadIcon />}
			>
				Connect Quickbooks
			</Button>
	)
}

export default QuickbooksOAuth
