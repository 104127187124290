import { useState, Fragment, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useSetNavBarTitle } from '../../hooks/nav'
import Button from '../ui/button'
import TextField from '../ui/TextField'
import TextFieldBlock from '../ui/TextFieldBlock'
import { Decimal128 } from 'bson'
import Currency from '../../../utils/currency'
import _ from 'lodash'
import { updateBatchTotals, syncToQuickbooks } from '../../actions/labor/payroll-batches'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		spacing: theme.spacing(3)
	}
}))

function sortBatches(batches) {
	return batches.sort((a, b) => {
		if (a.periodBeginningDate > b.periodBeginningDate) return 1
		if (a.periodBeginningDate < b.periodBeginningDate) return -1
		if (a.periodBeginningDate === b.periodBeginningDate) return 0
	})
}

function PayrollBatches () {
	useSetNavBarTitle('Payroll Batches')
	const classes = useStyles()
	const unsortedBatches = useSelector(state => state.data['payroll/batches'])

	if(!unsortedBatches) return <div>Loading...</div>

	const batches = sortBatches(unsortedBatches)

	return (
		<div className={classes.root}>
			<Table className={classes.table} props={{ stickyHeader: true }}>
				<TableHead>
					<TableRow>
						<TableCell width={50}></TableCell>
						<TableCell>Start Date</TableCell>
						<TableCell>End Date</TableCell>
						<TableCell>Gross Pay</TableCell>
						<TableCell>Net Pay</TableCell>
						<TableCell width={100}>Upload</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{batches.map(batch => (
						<Row key={ batch.batchNumber } batch={ batch } />
					))}
				</TableBody>
			</Table>
		</div>
	)
}

function Row({ batch }) {
	const dispatch = useDispatch()
	const [ open, setOpen ] = useState(false)
	const [ editing, setEditing ] = useState(false)
	const [ state, setState ] = useState({
		...batch,
		inBalance: Currency.equals(calculateTotalLiability(batch), batch.totalLiability)
	})
	const [ saving, setSaving ] = useState(false)
	const [ syncing, setSyncing ] = useState(false)
	const synced = (batch._sync && batch._sync.quickbooks)? true : false

	useEffect(() => {
		setState({
			...batch,
			inBalance: Currency.equals(calculateTotalLiability(batch), batch.totalLiability)
		})
		setEditing(false)
		setSaving(false)
		if(syncing && batch._sync && batch._sync.quickbooks) setSyncing(false)
	}, [batch])


	function calculateTotalLiability(state) {
		if (state) return Currency.sum([
				state.totalNetPay,
				state.totalFederalIncome,
				state.totalMedicare,
				state.totalSocialSecurity,
				state.totalStateIncome,
				state.totalStateSDI,
				state.totalMedicareER,
				state.totalSocialSecurityER,
				state.totalFUTA,
				state.totalStateSUI,
				state.totalStateETT
			])
		else return Decimal128.fromString('0.00')
	}

	function checkBalance(state) {
		if (state) {
			let totalLiability = calculateTotalLiability(state)
			if (Currency.equals(totalLiability, state.totalLiability)) return true
		}
		return false
	}

	const layout = {
		heading: `Batch ${batch.batchNumber} ${state.inBalance? ' (Balanced)' : ' (Out of Balance)'}`,
		blocks: [{
			heading: 'Batch Totals',
			fields: [{
				label: 'Gross Pay',
				type: 'currency',
				name: 'totalGrossPay'
			},{
				label: 'Net Pay',
				type: 'currency',
				name: 'totalNetPay'
			},{
				label: 'Sick Pay',
				type: 'currency',
				name: 'totalSickPay'
			},{
				label: 'Deductions',
				type: 'currency',
				name: 'totalDeductions'
			}]
		},{
			heading: 'Employee Taxes',
			fields: [{
				label: 'Federal Income Tax',
				type: 'currency',
				name: 'totalFederalIncome'
			},{
				label: 'Medicare Tax',
				type: 'currency',
				name: 'totalMedicare'
			},{
				label: 'Social Security Tax',
				type: 'currency',
				name: 'totalSocialSecurity'
			},{
				label: 'State Income Tax',
				type: 'currency',
				name: 'totalStateIncome'
			},{
				label: 'State SDI',
				type: 'currency',
				name: 'totalStateSDI'
			}]
		},{
			heading: 'Employer Taxes',
			fields: [{
				label: 'Medicare Tax',
				type: 'currency',
				name: 'totalMedicareER'
			},{
				label: 'Social Security Tax',
				type: 'currency',
				name: 'totalSocialSecurityER'
			},{
				label: 'FUTA Tax',
				type: 'currency',
				name: 'totalFUTA'
			},{
				label: 'State SUI',
				type: 'currency',
				name: 'totalStateSUI'
			},{
				label: 'State ETT',
				type: 'currency',
				name: 'totalStateETT'
			}]
		},{
			heading: 'Check Values',
			fields: [{
				label: 'Total Liability',
				type: 'currency',
				name: 'totalLiability'
			}]
		}]
	}

	const onChange = (e) => {
		setState(state => {
			let nextState = {
				...state,
				[e.target.name]: e.target.value
			}
			nextState.inBalance = checkBalance(nextState)
			return nextState
		})
	}

	const onEditClick = () => {
		setEditing(true)
	}

	const onCancelClick = () => {
		setEditing(false)
		setState(batch)
	}

	const onSaveClick = () => {
		setEditing(false)
		setSaving(true)
		dispatch(updateBatchTotals(batch._id, _.pick(state, [
			'totalGrossPay',
			'totalDeductions',
			'totalSickPay',
			'totalNetPay',
			'totalFederalIncome',
			'totalMedicare',
			'totalSocialSecurity',
			'totalStateIncome',
			'totalStateSDI',
			'totalMedicareER',
			'totalSocialSecurityER',
			'totalFUTA',
			'totalStateSUI',
			'totalStateETT',
			'totalLiability'
		])))
	}

	const onSyncClick = () => {
		setSyncing(!syncing)
		dispatch(syncToQuickbooks(state._id))
	}

	return (
		<Fragment>
			<TableRow
				hover
				roll="checkbox"
				key={state.batchNumber}
			>
					<TableCell>
						<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
							{open? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell>{state.periodBeginningDate.toLocaleDateString('en-US')}</TableCell>
					<TableCell>{state.periodEndDate.toLocaleDateString('en-US')}</TableCell>
					<TableCell>${state.totalGrossPay.toString()}</TableCell>
					<TableCell>${state.totalNetPay.toString()}</TableCell>
					<TableCell align="right">
						<Button
							variant="contained"
							color="primary"
							component="span"
							onClick={onSyncClick}
							disabled={!state.inBalance || editing || saving || syncing || synced}
						>
							{syncing? 'Syncing...' :
								synced? 'Synced' : 'Quickbooks'}
						</Button>
					</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Paper style={{padding: 15, margin: 20}} elevation={1}>
							<TextFieldBlock
								state={state}
								layout={layout}
								editable={editing}
								onChange={onChange}
							/>
							{ (!editing && !saving)? <Button onClick={onEditClick}>Edit</Button> : null }
							{ saving? <Button disabled={true}>Saving...</Button> : null }
							{ editing? <Button color='secondary' onClick={onCancelClick}>Cancel</Button> : null }
							{ editing? <Button onClick={onSaveClick} disabled={!state.inBalance}>Save</Button> : null }
							<Typography variant="h6" gutterBottom component="div">
								Paychecks
							</Typography>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell align="left">Check No</TableCell>
										<TableCell>First Name</TableCell>
										<TableCell>Last Name</TableCell>
										<TableCell align="right">Net Pay</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(state.checks).map((checkNumber) => (
										<TableRow key={checkNumber}>
											<TableCell align="left">{checkNumber}</TableCell>
											<TableCell>{state.checks[checkNumber].firstName}</TableCell>
											<TableCell>{state.checks[checkNumber].lastName}</TableCell>
											<TableCell align="right">${state.checks[checkNumber].netPay.toString()}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Paper>
					</Collapse>
				</TableCell>
			</TableRow>
		</Fragment>
	)
}

export default PayrollBatches
