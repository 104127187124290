let getBaseState = () => ({
	appBarTitle: ''
})

const nav = (state = getBaseState(), action) => {
	switch (action.type) {
		case 'SET_APP_BAR_TITLE': return {
			appBarTitle: action.title
		}
		default:
			return state
	}
}

export default nav
