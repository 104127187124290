import React from 'react'
import { useSelector } from 'react-redux'
import { useSetNavBarTitle } from '../../hooks/nav'
import { ObjectId } from 'bson'

import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1)
	},
	heading: {
		fontSize: theme.typography.pxToRem(17),
		fontWeight: theme.typography.fontWeightRegular
	}
}))

function Vendor({ match }) {
	const vendorId = match.params.vendorId
	const vendor = useSelector(state => {
		const vendors = state.data['vendors']
		let selectedVendor = undefined
		if(vendorId !== 'new') {
			const vendorOid = ObjectId(vendorId)
			for(let vendor of vendors) {
				if(vendor._id.equals(vendorOid)) selectedVendor = vendor
			}
		}
		return selectedVendor
	})
	const navBarTitle = vendor? vendor.name : 'New Vendor'
	useSetNavBarTitle(navBarTitle)
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.heading}>General Info</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Grid
						container
					>
						<Grid
							container
							spacing={3}
						>
							<Grid item xs={12}>
								Name<br/>
								<TextField
									label="Name"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								General Info<br/>
								<TextField
									label="Type"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
								<TextField
									label="Locations Serviced"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
								<TextField
									label="Main Phone"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								Address Info<br/>
								<TextField
									label="Address 1"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
								<TextField
									label="Address 2"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
								<TextField
									label="City"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
								<TextField
									label="State"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
								<TextField
									label="Zip Code"
									margin="dense"
									variant="outlined"
									fullWidth
								/>
							</Grid>
						</Grid>
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
					<Typography className={classes.heading}>Accounts</Typography>
				</ExpansionPanelSummary>
			</ExpansionPanel>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
					<Typography className={classes.heading}>Contacts</Typography>
				</ExpansionPanelSummary>
			</ExpansionPanel>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
					<Typography className={classes.heading}>Invoices</Typography>
				</ExpansionPanelSummary>
			</ExpansionPanel>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
					<Typography className={classes.heading}>Items</Typography>
				</ExpansionPanelSummary>
			</ExpansionPanel>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
					<Typography className={classes.heading}>Notes</Typography>
				</ExpansionPanelSummary>
			</ExpansionPanel>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
					<Typography className={classes.heading}>Settings</Typography>
				</ExpansionPanelSummary>
			</ExpansionPanel>
		</div>
	)
}

export default Vendor
