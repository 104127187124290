import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Route } from 'react-router-dom'
import { useHistory } from 'react-router'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { logOut } from '../actions/auth'

import CssBaseline from '@material-ui/core/CssBaseline'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Container from '@material-ui/core/Container'

import Hidden from '@material-ui/core/Hidden'
import DashboardIcon from '@material-ui/icons/DashboardRounded'
import SalesIcon from '@material-ui/icons/AttachMoneyRounded'
import InventoryIcon from '@material-ui/icons/LocalShippingRounded'
import LaborIcon from '@material-ui/icons/DirectionsRunRounded'
import MenuBookIcon from '@material-ui/icons/RestaurantMenuRounded'
import ManagementIcon from '@material-ui/icons/CheckBoxRounded'
import PersonnelIcon from '@material-ui/icons/PeopleRounded'
import AccountIcon from '@material-ui/icons/BusinessRounded'
import SettingsIcon from '@material-ui/icons/SettingsApplicationsRounded'
import DeveloperIcon from '@material-ui/icons/DevicesRounded'
import ConsoleIcon from '@material-ui/icons/ComputerRounded'
import LockIcon from '@material-ui/icons/LockRounded'

import NavBar from './nav-bar'
import Dashboard from './dashboard'
import Sales from './sales'
import Inventory from './inventory/Inventory'
import InventoryItems from './inventory/InventoryItems'
import InventoryItem from './inventory/InventoryItem'
import PayrollBatches from './labor/PayrollBatches'
import Personnel from './personnel'
import Vendors from './vendors/Vendors'
import Vendor from './vendors/Vendor'
//import Developer from './developer'
import Files from './files'
import Camera from './camera'
import Console from './console'
import Floorplan from './pos/floorplan'
import QuickbooksOAuth from './quickbooks/QuickbooksOAuth'
import FloorplanEditor from './FloorplanEditor'
import RmEmployees from './developer/integrations/restaurant-manager/employees'
import Unemployment from './ui'
import Payroll from './payroll'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth
	},
	content: {
		flexGrow: 1,
		padding: '0px'
	},
	container: {
		padding: '0px'
	},
	nested: {
		paddingLeft: theme.spacing(4)
	}
}))

//const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

function RootNav ({ match, permissions, logOut }) {
	const classes = useStyles()
	const history = useHistory()
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))
	const [mobileOpen, setMobileOpen] = React.useState(mdUp)
	const [expandedMenus, setExpandedMenus] = React.useState({
		'Developer': false
	})

	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen)
	}

	function listItemClick(path) {
		if (path === '/app/logout') {
			logOut()
		} else {
			if(!mdUp) setMobileOpen(false)
			history.push(path)
		}
	}

	const handleExpandClick = (path, key) => {
		setExpandedMenus({
			...expandedMenus,
			[key]: !expandedMenus[key]
		})
	}

	function createDrawerItemList(items) {
		let itemList = []
		for (const item of items) {
			let [permission, title, Icon, path, children = []] = item
			children = createDrawerItemList(children)
			if (permission) itemList.push({ title: title, Icon: Icon, path: path, children: children })
		}
		return itemList
	}

	let drawerItems = createDrawerItemList([
		[permissions.sales, 'Dashboard', DashboardIcon, '/app/dashboard'],
		[permissions.sales, 'Sales', SalesIcon, '/app/sales'],
		[permissions.inventory, 'Inventory', InventoryIcon, '/app/inventory', [
			[permissions.inventory, 'Items', InventoryIcon, '/app/inventory/items']
		]],
		[permissions.labor, 'Labor', LaborIcon, '/app/labor', [
			[permissions.unemployment, 'Unemployment', LaborIcon, '/app/labor/ui'],
			[permissions.timecards, 'Payroll', LaborIcon, '/app/labor/payroll'],
			[permissions['payroll/batches'], 'Payroll Batches', LaborIcon, '/app/labor/payroll/batches']
		]],
		[permissions.menu, 'Menu', MenuBookIcon, '/app/menu'],
		[permissions.management, 'Management', ManagementIcon, '/app/manage'],
		[permissions.personnel, 'Personnel', PersonnelIcon, '/app/personnel'],
		[permissions.vendors, 'Vendors', AccountIcon, '/app/vendors'],
		[permissions.settings, 'Settings', SettingsIcon, '/app/settings'],
		[permissions.developer, 'Developer', DeveloperIcon, '/app/dev', [
			[permissions.developer, 'Employees', DeveloperIcon, '/app/dev/integrations/rm/employees'],
			[permissions.developer, 'Files', DeveloperIcon, '/app/dev/files'],
			[permissions.developer, 'Floorplan Editor', DeveloperIcon, '/app/dev/editor'],
			[permissions.developer, 'Camera', DeveloperIcon, '/app/dev/camera'],
			[permissions.developer, 'Floorplan', DeveloperIcon, '/app/dev/floorplan'],
			[permissions.developer, 'Quickbooks', DeveloperIcon, '/app/dev/quickbooks']
		]],
		[permissions.console, 'Console', ConsoleIcon, '/app/console'],
		[permissions.timecards, 'Payroll', LaborIcon, '/app/payroll'],
		[permissions.unemployement, 'Unemployment', SalesIcon, '/app/ui'],
		[true, 'Logout', LockIcon, '/app/logout']
	])

	let processChildren = (title, children) => {
		if(children.length) {
			return (
				<Collapse in={expandedMenus[title]} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{children.map(({ title, Icon, path, children }) => (
							<ListItem
								selected={history.location.pathname === path}
								button
								className={classes.nested}
								onClick={() => listItemClick(path)}
								key={title}
							>
								<ListItemIcon>
									<Icon />
								</ListItemIcon>
								<ListItemText primary={title} />
							</ListItem>
						))}
					</List>
				</Collapse>
			)
		}
	}

	let menu = drawerItems.map(({ title, Icon, path, children }) =>
		<div key={title}>
			<ListItem
				selected={history.location.pathname === path}
				button
				onClick={() => !children.length? listItemClick(path) : handleExpandClick(path, title)}
			>
				<ListItemIcon>
					<Icon />
				</ListItemIcon>
				<ListItemText primary={title} />
				{ !children.length ? <div /> : expandedMenus[title] ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			{processChildren(title, children)}
		</div>
	)

	const drawer = (
		<div>
			<div className={classes.toolbar} />
			<Divider />
			<List>
				{menu}
			</List>
		</div>
	)

	return (
		<div className={classes.root}>
			<CssBaseline />
			<NavBar drawerWidth={drawerWidth} onToggle={handleDrawerToggle} />
			<nav className={classes.drawer}>
				<Hidden mdUp implementation="css">
					<SwipeableDrawer
						variant="temporary"
						anchor={theme.direction === 'rtl'? 'right': 'left'}
						open={mobileOpen}
						onOpen={handleDrawerToggle}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true
						}}
					>
						{drawer}
					</SwipeableDrawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<SwipeableDrawer
						classes={{
							paper: classes.drawerPaper
						}}
						variant="permanent"
						onOpen={handleDrawerToggle}
						onClose={handleDrawerToggle}
						open={mobileOpen}
					>
						{drawer}
					</SwipeableDrawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Container className={classes.container}>
					<Route path={`${match.path}/dashboard`} component={Dashboard}/>
					<Route path={`${match.path}/sales`} component={Sales}/>
					<Route exact path={`${match.path}/inventory`} component={Inventory}/>
					<Route exact path={`${match.path}/inventory/items`} component={InventoryItems}/>
					<Route path={`${match.path}/inventory/items/:itemId`} component={InventoryItem}/>
					<Route path={`${match.path}/labor/payroll/batches`} component={PayrollBatches}/>
					<Route path={`${match.path}/personnel`} component={Personnel}/>
					<Route exact path={`${match.path}/vendors`} component={Vendors}/>
					<Route path={`${match.path}/vendors/:vendorId`} component={Vendor}/>
					<Route path={`${match.path}/dev/files`} component={Files}/>
					<Route path={`${match.path}/dev/editor`} component={FloorplanEditor}/>
					<Route path={`${match.path}/dev/camera`} component={Camera}/>
					<Route path={`${match.path}/dev/integrations/rm/employees`} component={RmEmployees}/>
					<Route path={`${match.path}/dev/floorplan`} component={Floorplan}/>
					<Route path={`${match.path}/dev/quickbooks`} component={QuickbooksOAuth}/>
					<Route path={`${match.path}/console`} component={Console}/>
					<Route path={`${match.path}/ui`} component={Unemployment}/>
					<Route path={`${match.path}/payroll`} component={Payroll}/>
				</Container>
			</main>
		</div>
	)
}

const mapStateToProps = state => ({
	permissions: state.auth.permissions
})

const mapDispatchToProps = dispatch => ({
	logOut: () => dispatch(logOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(RootNav)
