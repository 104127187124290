import { wsSend } from '../websocket' 

export const updateBatchTotals = (_id, batchTotals) => {
	return wsSend({
		type: 'update',
		path: 'labor/payroll/batches',
		_id: _id,
		data: batchTotals
	})
}

export const syncToQuickbooks = (_id) => {
	return wsSend({
		type: 'sync',
		path: 'labor/payroll/batches',
		_id: _id
	})
}
