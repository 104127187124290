import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux'
import { logIn } from '../actions/auth'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
	  '@global': {
		      body: {
				        backgroundColor: theme.palette.common.white,
				      },
		    },
	  paper: {
		      marginTop: theme.spacing(8),
		      display: 'flex',
		      flexDirection: 'column',
		      alignItems: 'center',
		    },
	  avatar: {
		      margin: theme.spacing(1),
		      backgroundColor: theme.palette.secondary.main,
		    },
	  form: {
		      width: '100%', // Fix IE 11 issue.
		      marginTop: theme.spacing(1),
		    },
	  submit: {
		      margin: theme.spacing(3, 0, 2),
		    },
})

class SignIn extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			password: ''
		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSubmit = (e) => {
		e.preventDefault()
		this.props.logIn(this.state.email, this.state.password)
	}

	render() {
		const { classes } = this.props
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<form className={classes.form} noValidate>
							{this.props.incorrectLogin && (
								<Container>
									<Typography component="h6" align="center">
										Email address or password incorrect
									</Typography>
								</Container>
							)}
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								onChange={e => this.handleChange(e)}
								autoFocus
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								onChange={e => this.handleChange(e)}
							/>
							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={e => this.onSubmit(e)}
							>
								Sign In
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href="#" variant="body2">
										Forgot password?
									</Link>*
								</Grid>
								<Grid item>
									<Link component={RouterLink} to="/signup" variant="body2">
										{"Don't have an account? Sign Up"}
									</Link>
								</Grid>
							</Grid>
						</form>
					</div>
				{/*<Box mt={8}>
        			<Copyright />
				</Box>*/}
			</Container>
  		)
	}
}

const mapStateToProps = state => ({
	incorrectLogin: state.auth.incorrectLogin
})

const mapDispatchToProps = dispatch => ({
	logIn: (email, password) => dispatch(logIn(email, password))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn))
