import { ObjectId } from 'bson'

export const wsConnected = () => {
	return {
		type: 'WEBSOCKET_CONNECTED'
	}
}

export const wsErrored = (err, subscriptions) => {
	return dispatch => {
		dispatch({
			type: 'WEBSOCKET_ERRORED',
			error: err.data
		})
		dispatch(resetSubscriptions(subscriptions))
	}
}

export const wsClosed = (subscriptions) => {
	return dispatch => {
		dispatch({ type: 'WEBSOCKET_CLOSED' })
		dispatch(resetSubscriptions(subscriptions))
	}
}

export const send = (msg) => {
	return {
		type: 'WEBSOCKET_SEND_QUEUE_PUSH',
		msg: {
			_id: ObjectId().toString(),
			body: msg
		}
	}
}

export const wsSend = send

export const confirmSend = (_id, data) => {
	return {
		type: 'WEBSOCKET_CONFIRM_SEND',
		_id: _id,
		data: data
	}
}

export const wsSubscribe = (path, email) => {
	return dispatch => {
		console.log(path, email)
		dispatch(send({
			type: 'subscribe',
			path: path,
			subscribe: true,
			filter: { email: email }
		}))
		dispatch({
			type: 'WEBSOCKET_REQUEST_SUBSCRIBE',
			path: path
		})
	}
}

export const wsSubscribed = (path) => {
	return {
		type: 'WEBSOCKET_SUBSCRIBED',
		path: path
	}
}

export const wsUnsubscribe = (path) => {
	return dispatch => {
		dispatch(send({
			type: 'subscribe',
			path: path,
			subscribe: false
		}))
		dispatch({
			type: 'WEBSOCKET_REQUEST_UNSUBSCRIBE',
			path: path
		})
	}
}

export const wsUnsubscribed = (path) => {
	return {
		type: 'WEBSOCKET_UNSUBSCRIBED',
		path: path
	}
}

export const wsUnsubscribeAll = (subscriptions) => {
	return dispatch => {
		for (let [path, status] of Object.entries(subscriptions)) {
			if(status === 'subscribing' || status === 'subscribed') {
				dispatch(wsUnsubscribe(path))
			}
		}
	}
}

export const resetSubscriptions = (subscriptions) => {
	return dispatch => {
		for (let [path, status] of Object.entries(subscriptions)) {
			if(status === 'subscribing' || status === 'subscribed') {
				dispatch(wsUnsubscribe(path))
				dispatch(wsSubscribe(path))
			}
		}
	} 
}

export const wsSubscribeToPermitted = (permissions, email) => {
	return dispatch => {
		for(let [path, value] of Object.entries(permissions)) {
			if (value) {
				dispatch(wsSubscribe(path, email))
			}
		}
	}
}
