import { combineReducers } from 'redux'
import auth from './auth'
import nav from './nav'
import data from './data'
import ws from './websocket'

const appReducer = combineReducers({
	auth,
	nav,
	data,
	ws
})

const rootReducer = (state, action) => {
	if (action.type === 'LOG_OUT') {
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

export default rootReducer
