import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import PrivateRoute from './private-route'
import RootNav from './root-nav'
import SignIn from './sign-in'
import SignUp from './sign-up'

import { checkCredentials } from '../actions/auth'

import { startWebsocket } from '../websocket'

require('../static/styles.css');

startWebsocket()

function App({ authenticated, checkedForSession, checkCredentials }) {
	if(!checkedForSession && !authenticated) checkCredentials(window.location.pathname)
	return (
		<div>
			<Switch>
				<Route exact path="/login" component={SignIn} />
				<Route exact path="/signup" component ={SignUp} />
				<Route exact path="/forgotpassword" />
				<PrivateRoute isAuthenticated={authenticated} checkedForSession={checkedForSession} path="/app" component={RootNav} />
				<Redirect to={authenticated? "/app/dashboard": "/login" } />
			</Switch>
		</div>
	)	
}

const mapStateToProps = state => ({
	authenticated: state.auth.authenticated,
	checkedForSession: state.auth.checkedForSession
})

const mapDispatchToProps = dispatch => ({
	checkCredentials: (path) => dispatch(checkCredentials(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
