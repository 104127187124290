import React from 'react'
import Typography from '@material-ui/core/Typography'
import MuiTextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import { Decimal128 } from 'bson'

function NumberFormatCustom({ inputRef, onChange, ...props }) {
	return (
		<NumberFormat
			{... props}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				})
			}}
			thousandSeparator
			prefix="$"
			decimalScale={2}
		/>
	)
}

function TextField({ editable, name, currency, label, ...props }) {
	let { value } = props
	if (editable) {
		let inputProps
		let { onChange } = props
		if (currency) {
			inputProps = {
				inputComponent: NumberFormatCustom
			}
			if (typeof props.value === 'number') value = props.value.toFixed(2)
			else if (typeof props.value === 'string') value = Number(props.value).toFixed(2)
			else if (props.value instanceof Decimal128) {
				value = props.value.toString()
				onChange = (e) => {
					if (e.target.value !== '-') {
						e.target.value = Decimal128.fromString(Number(e.target.value).toFixed(2))
						props.onChange(e)
					}
				}
			}
		}
		return (
			<MuiTextField
				name={name}
				label={label}
				margin="dense"
				value={value}
				onChange={onChange}
				InputProps={currency? {
					inputComponent: NumberFormatCustom
				} : {}}
			/>
		)
	} else {
		let text = `${label}: `
		if (value !== undefined) {
			if (currency) {
				let currencyString
				if (typeof value === 'number') currencyString = value.toFixed(2)
				else if (typeof value === 'string') currencyString = Number(value).toFixed(2)
				else if (value instanceof Decimal128) {
					currencyString = value.toString()
				}
				if (currencyString < 0 ) text += `-$${currencyString.replace('-', '')}`
				else text += `$${currencyString}`
			} else {
				text += value
			}
		}
		return (
			<Typography variant="body1" gutterBottom component="div">
				{text}
			</Typography>
		)
	}
}

export default TextField
