import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/AddRounded'
import { useHistory } from 'react-router'
import { useSetNavBarTitle } from '../hooks/nav'
import User from './user'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(3),
		right: theme.spacing(3)
	},
	container: {
		padding: '0px'
	}
}))

function Personnel ({ match, personnel }) {
	useSetNavBarTitle('Personnel')
	const classes = useStyles()
	const history = useHistory()

	function handleAccountClick(event, row) {
		history.push('/app/personnel/' + row._id)
	}

	function handleNewAccountClick(e) {
		history.push('/app/new')
	}

	return (
		<div className={classes.root}>
			<Route exact path={`${match.path}`}>
				<Table className={classes.table} props={{ stickyHeader: true }}>
					<TableHead>
						<TableRow>
							<TableCell>First Name</TableCell>
							<TableCell>Last Name</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{personnel.map(row => (
							<TableRow
								hover
								roll="checkbox"
								onClick={event => handleAccountClick(event, row)}
								key={row._id}
							>
									<TableCell>{row.firstName}</TableCell>
									<TableCell>{row.lastName}</TableCell>
									<TableCell>Active</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Fab className={classes.fab} onClick={e => handleNewAccountClick(e)} color="primary">
					<AddIcon />	
				</Fab>
			</Route>
			<Route path={`${match.path}/:userId`} render={ (props) => <User {...props} personnel={personnel} />} />
		</div>
	)
}

const mapStateToProps = state => ({
	personnel: state.data.personnel
})

export default connect(mapStateToProps)(Personnel)
