import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
	appBar: props => ({
		marginLeft: props.drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${props.drawerWidth}px)`
		}
	}),
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	title: {
		flexGrow: 1
	}
}))

function NavBar ({ title, drawerWidth, onToggle }) {
	const classes = useStyles({ drawerWidth: drawerWidth })
	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				<IconButton
					edge="start"
					className={classes.menuButton}
					color="inherit"
					onClick={onToggle}
				>
					<MenuIcon  />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{ title }
				</Typography>
			</Toolbar>
		</AppBar>
	)
}

const mapStateToProps = state => ({
	title: state.nav.appBarTitle
})

export default connect(mapStateToProps)(NavBar)
