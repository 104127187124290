import React from 'react'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

function UploadButton ({ onUpload, onProgress }) {
	let handleUpload = e => {
		let files = e.target.files
		for (let i = 0; i < files.length ; i++) {
			let file = files[0]
			const reader = new FileReader()
			switch(file.type) {
				/*case 'text/csv':
					reader.readAsText(file)
					break
				case 'application/pdf':
					reader.readAsDataURL(file)
					break
				case 'image/svg+xml':
					reader.readAsText(file)
					break*/
				default:
					reader.readAsDataURL(file)
			}
			reader.onprogress = p => {
				if( onProgress ) {
					onProgress({
						loaded: p.loaded,
						total: p.total,
						timeStamp: p.timeStamp,
						lengthComputable: p.lengthComputable
					})
				}
			}
			reader.onloadend = e => {
				onUpload({
					name: file.name,
					type: file.type,
					size: file.size,
					data: reader.result
				})
			}
		}
		
	}

	return (
		<label htmlFor="upload">
			<input
				style={{ display: 'none' }}
				id="upload"
				name="upload"
				type="file"
				onChange={handleUpload}
			/>
			<Button
				variant="contained"
				color="primary"	
				component="span"
				startIcon={<CloudUploadIcon />}
			>
				Upload
			</Button>
		</label>
	)
}

export default UploadButton
