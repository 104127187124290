import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from './TextField'
import MongoChip from './MongoChip'

function TextFieldBlock({ state, layout, editable, onChange, ...props }) {
	return (
		<Box margin={1}>
			<Typography variant="h6" gutterBottom component="div">
				{ layout.heading }
			</Typography>
			<Grid container spacing={1}>
				{ layout.blocks.map((block) => (
					<Grid
						item
						xs={ (layout.blocks.length >= 1)? 12 : undefined }
						md={ (layout.blocks.length >= 2)? 6 : undefined }
						lg={ (layout.blocks.length >= 3)? 4 : undefined }
						key={block.heading}
					>
						<Grid
							container
						>
							<Grid item xs={12}>
								<Typography variant="subtitle1" gutterBottom component="div">
									{ block.heading }
								</Typography>
							</Grid>
							{ block.fields.map((field) => {
								var val
								if (field.type === 'text' || field.type === 'currency') {
									val = (<TextField
										label={field.label}
										editable={editable}
										currency={(field.type === 'currency')? true : false}
										name={field.name}
										value={state[field.name]}
										onChange={onChange}
										fullWidth
									/>)
								} else if (field.type === 'mongo-chip') {
									val = (
										<MongoChip
											field={field}
											value={state[field.name]}
										/>
									)
								}
								return (
									<Grid item xs={12} sm={editable? 6 : 12} key={field.name}>
										{val}
									</Grid>
								)
							})}
						</Grid>
					</Grid>
				))}
			</Grid>
		</Box>
	)
}

export default TextFieldBlock
