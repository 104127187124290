import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/AddRounded'
import { useHistory } from 'react-router'
import { useSetNavBarTitle } from '../../hooks/nav'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(3),
		right: theme.spacing(3)
	}
}))

function Vendors () {
	useSetNavBarTitle('Vendors')
	const classes = useStyles()
	const history = useHistory()
	const vendors = useSelector(state => state.data.vendors)

	function handleVendorClick(event, vendor) {
		history.push('/app/vendors/' + vendor._id)
	}

	function handleNewVendorClick(e) {
		history.push('/app/vendors/new')
	}

	return (
		<div className={classes.root}>
			<Table className={classes.table} props={{ stickyHeader: true }}>
				<TableHead>
					<TableRow>
						<TableCell>Account</TableCell>
						<TableCell>Balance</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{vendors.map(vendor => (
						<TableRow
							hover
							roll="checkbox"
							onClick={event => handleVendorClick(event, vendor)}
							key={vendor.name}
						>
								<TableCell>{vendor.name}</TableCell>
								<TableCell>{vendor.balance}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Fab className={classes.fab} onClick={e => handleNewVendorClick(e)} color="primary">
				<AddIcon />
			</Fab>
		</div>
	)
}

export default Vendors
