import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, isAuthenticated, checkedForSession, ...rest}) => {
	return (
		<Route
			{...rest}
			render={props => (
				isAuthenticated? (
					<Component {...props} />
				) : 
				!checkedForSession? (
					<div>Authenticating</div>
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location }}} />
				)
			)}
		/>
	)
}

export default PrivateRoute
