import React from 'react'
import { useSetNavBarTitle } from '../hooks/nav'

function Sales () {
	useSetNavBarTitle('Sales')
	return (
		<div>Sales</div>
	)
}

export default Sales
