import React from 'react'
import MaterialButton from '@material-ui/core/Button'

function Button ({ children, variant='contained',  color = 'primary', ...props }) {
	return (
		<MaterialButton
			{ ...props }
			variant={variant}
			color={color}
		>
			{children}
		</MaterialButton>
	)
}

export default Button
