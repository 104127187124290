import MuiTextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const CheckboxOutlineSmall = <CheckBoxOutlineBlankIcon fontSize="small" />
const CheckedIconSmall = <CheckBoxIcon fontSize="small" />

function MongoChip({ field, value }) {
	const optionById = {}
	const optionIds = []
	for (let option of field.options) {
		optionIds.push(option._id)
		optionById[option._id] = option
	}
	return (
		<Autocomplete
			multiple
			id="size-small-standard-multi"
			size="small"
			name={field.name}
			value={value}
			options={optionIds}
			getOptionLabel={(optionId) => {
				return field.getOptionLabel(optionById[optionId])
			}}
			isOptionEqualToValue={(option, value) => option.equals(value)}
			onChange={(e, values) => {
				field.onChange(field.name, values)
			}}
			disableCloseOnSelect
			renderOption={(props, optionId, { selected }) => (
				<li {...props}>
					<Checkbox
					icon={CheckboxOutlineSmall}
					checkedIcon={CheckedIconSmall}
					style={{ marginRight: 8 }}
					checked={selected}
					/>
					{field.getOptionLabel(optionById[optionId])}
				</li>
			)}
			renderInput={(params) => (
				<MuiTextField
				{...params}
				variant="standard"
				label={field.label}
				/>
			)}
		/>
	)
}

export default MongoChip
