const baseState = {
	connected: false,
	msgQueue: [],
	subscriptions: {}
}

const data = (state = {...baseState}, action) => {
	let newState
	switch (action.type) {
		case 'WEBSOCKET_CONNECTED':
			return {
				...state,
				connected: true
			}
		case 'WEBSOCKET_ERRORED':
			return {
				...state,
				connected: false
			}
		case 'WEBSOCKET_CLOSED':
			return {
				...state,
				connected: false
			}
		case 'WEBSOCKET_SEND_QUEUE_PUSH':
			return {
				...state,
				msgQueue: [...state.msgQueue, action.msg]
			}
		case 'WEBSOCKET_CONFIRM_SEND':
			return {
				...state,
				msgQueue: state.msgQueue.filter(msg => msg._id !== action._id)
		   	}
		case 'WEBSOCKET_REQUEST_SUBSCRIBE':
			newState = { ...state }
			newState.subscriptions[action.path] = 'subscribing'
			return newState 
		case 'WEBSOCKET_SUBSCRIBED':
			newState = { ...state }
			newState.subscriptions[action.path] = 'subscribed'
			return newState 
		case 'WEBSOCKET_REQUEST_UNSUBSCRIBE':
			newState = { ...state }
			newState.subscriptions[action.path] = 'unsubscribing'
			return newState 
		case 'WEBSOCKET_UNSUBSCRIBED':
			newState = { ...state }
			newState.subscriptions[action.path] = 'unsubscribed'
			return newState 
		default:
			return state
	}
}

export default data
