const baseState = {
	checkedForSession: false,
	checkingCredentials: false,
	authenticated: false,
	incorrectLogin: false,
	user: '',
	permissions: {}
}

const auth = (state = {...baseState}, action) => {
	switch (action.type) {
		case 'LOG_IN': 
			return {
				...baseState,
				checkedForSession: true,
				authenticated: true,
				incorrectLogin: false,
				user: action.user,
				permissions: action.permissions
			}
		case 'LOG_IN_FAILED': 
			return {
				...baseState,
				checkedForSession: true,
				incorrectLogin: true,
			}
		case 'LOG_OUT':
			return {
				...baseState,
				checkedForSession: true
			}
		case 'CHECKING_CREDENTIALS':
			return {
				...baseState,
				checkingCredentials: true
			}
		default:
			return state 
	}
}

export default auth
