const { Decimal128 } = require('bson')
const { Decimal } = require('decimal.js')

module.exports.add = (a, b, precision = 2) => {
	let total = Decimal(a.toString())
	total = total.add(Decimal(b.toString()))
	total = Decimal128.fromString(total.toFixed(precision))
	return total
}

module.exports.equals = (a, b) => {
	return (Decimal(a.toString()).equals(Decimal(b.toString())))? true : false
}

module.exports.negate = function negate(a, precision = 2) {
	let number = Decimal(a.toString())
	number = number.neg()
	return Decimal128.fromString(number.toFixed(precision))
}

module.exports.subtract = (a, b, precision = 2) => {
	let total = Decimal(a.toString())
	total = total.sub(Decimal(b.toString()))
	total = Decimal128.fromString(total.toFixed(precision))
	return total
}

module.exports.sub = module.exports.subtract

module.exports.sum = (values, precision = 2) => {
	let sum = Decimal('0.00')
	for(let value of values) {
		sum = sum.add(Decimal(value.toString()))
	}
	sum = Decimal128.fromString(sum.toFixed(precision))
	return sum
}

module.exports.toNumber = (a, precision = 2) => {
	return Number(Number(a.toString()).toFixed(precision))
}
