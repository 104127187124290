import React, { useRef, useState } from 'react'
import { useSetNavBarTitle } from '../../hooks/nav'
import SeatButton from './seat-button'

function Floorplan ({ svg, paths }) {
	useSetNavBarTitle('Floorplan')
	let rect = useRef()
	const [buttonState, setButtonState] = useState(false)

	function test(e) {
		if(buttonState) {
			rect.current.setAttribute("fill", "black")
			setButtonState(false)
		} else {
			rect.current.setAttribute("fill", "red")
			setButtonState(true)
		}
	}

	const seatProps = {
		strokeLinejoin: "round",
		rgb: {
			r: 220,
			g: 220,
			b: 220
		},
		opacity: 1,
		id: "seat1",
		d:"M38.577301 333.872803 L7.858902 333.872803 L7.858902 303.154785 L38.577301 303.154785 L38.577301 333.872803 Z",
		fill: "none",
		strokeWidth: 4
	}

	/*return (
		<div>
			<svg
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xmlns={svg.xmlns}
				version={svg.version}
				width={svg.width}
				height={svg.height}
				viewBox={svg.viewBox}
				className="us"
				pointerEvents="none"
			>
				<g>
					{paths.map((path) => (
							<SeatButton {...path} />
					))}
				</g>
			</svg>
		</div>
	)*/
	return (
		<div>Floorplan</div>
	)
}

export default Floorplan
