import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import WebCam from 'react-webcam'
import { useSetNavBarTitle } from '../hooks/nav'

const useStyles = makeStyles(theme => ({
	camera: {
		position: 'fixed',
		height: '100%',
		width: '100%',
		objectFit: 'cover'
	},
	cameraSensor: {
		position: 'fixed',
		height: '100%',
		width: '100%',
		objectFit: 'cover'
	},
	cameraView: {
		position: 'fixed',
		height: '100%',
		width: '100%',
		objectFit: 'cover'
	},
	cameraOutput: {
		position: 'fixed',
		height: '100%',
		width: '100%',
		objectFit: 'cover'
	},
	cameraTrigger: {
		width: '200px',
		backgroundColor: 'black',
		position: 'fixed',
		bottom: '30px',
		left: 'calc(50% - 100px)'
	}
}))

function Camera() {
	useSetNavBarTitle('Camera')
	const classes = useStyles()
	const webcamRef = React.useRef(null)
	const capture = React.useCallback(() => {
		//const imageSrc = webcamRef.current.getScreenshot()
		webcamRef.current.getScreenshot()
	}, [webcamRef])
	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: { exact: "environment" }
	}

	return (
		<>
			<WebCam 
				className={classes.camera}
				audio={false}
				ref={webcamRef}
				screenshotFormat="image/jpeg"
				videoConstraints={videoConstraints}
			/>
			<button onClick={capture}>Capture</button>
		</>
	)
}

export default Camera
