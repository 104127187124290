import React from 'react'
import { useSetNavBarTitle } from '../hooks/nav'
import UploadButton from './ui/upload-button'
import { parseString } from 'xml2js'
import Floorplan from './pos/floorplan'

function svgParser(svg) {
	let props = {
		svg: {},
		paths: []
	}

	if(svg.svg) {
		svg = svg.svg
		props.svg['xmlms'] = svg.$['xmlns']
		props.svg['viewBox'] = svg.$['viewBox']
		props.svg['height'] = svg.$['height']
		props.svg['width'] = svg.$['width']
		if(svg.g) {
			svg.g.forEach((g) => {
				if(g.path) {
					g.path.forEach((path) => {
						props.paths.push(path.$)
					})
				}
			})
		}
	}
	return props
}

function FloorplanEditor () {
	useSetNavBarTitle('Floorplan Editor')
	const [floorplanProps, setFloorplanProps] = React.useState({})

	function onUpload(data) {
		console.log(data)
		parseString(data.data, (err, svg) => {
			const props = svgParser(svg)
			console.log(props)
			setFloorplanProps(props)
		})
	}

	return (
		<div>
			<UploadButton onUpload={onUpload} />
			{floorplanProps.svg? <Floorplan {...floorplanProps} /> : <div />}
		</div>
	)
}

export default FloorplanEditor
