import { outputToConsole } from './actions/data'
import { wsConnected, wsErrored, wsClosed } from './actions/websocket'
//import { pushLog } from './actions/data'
import store from './store'
import { confirmSend, wsSubscribed, wsUnsubscribed } from './actions/websocket'
import { replaceData } from './actions/data'
import { EJSON } from 'bson'

const location = window.location

export const startWebsocket = async () => {
	var getWs
	var openMessages = {}
	var oldQueue = []
	let getNewWs = () => new Promise(async (res, rej) => {
		let dispatch = store.dispatch
		dispatch(outputToConsole('Starting WebSocket'))
		let ws = new WebSocket(`wss://${location.hostname}:${location.port}/ws`)
		ws.onopen = () => {
			dispatch(wsConnected(ws))
			dispatch(outputToConsole('WebSocket Connected'))
			ws.onmessage = (msg, flags) => {
				let data = EJSON.parse(msg.data)
				switch(data.type) {
					case 'subscribe':
						if (data.subscribed) dispatch(wsSubscribed(data.path))
						else dispatch(wsUnsubscribed(data.path))
						break
					case 'replace_data':
						dispatch(replaceData(data.path, data.data))
						break
					default:
						break
				}
				if(openMessages[data._id]) {
					delete openMessages[data._id]
					dispatch(confirmSend(data._id, data))
				}

				//if(data.log) dispatch(pushLog(data.log))
			}
			ws.onerror = (err) => {
				unsubscribe()
				openMessages = {}
				oldQueue = []
				dispatch(wsErrored(err, store.getState().ws.subscriptions))
				dispatch(outputToConsole('WebSocket Errored'))
				ws.close()
			}
			ws.onclose = () => {
				unsubscribe()
				openMessages = {}
				oldQueue = []
				dispatch(wsClosed(store.getState().ws.subscriptions))
				dispatch(outputToConsole('WebSocket Closed'))
				setTimeout(async () => {
					getWs = getNewWs()
					sendMessages()
				}, 1000)
			}
			var sendMessages = () => {
				let newQueue = store.getState().ws.msgQueue
				if (newQueue !== oldQueue) {
					for (let msg of newQueue) {
						if (!openMessages[msg._id]) {
							openMessages[msg._id] = msg
							getWs.then(ws => {
								ws.send(EJSON.stringify(msg))
							})
						}
					}
					oldQueue = newQueue
				}
			}
			var unsubscribe = store.subscribe(sendMessages)
			res(ws)
		}
	})
	getWs = getNewWs()
}
