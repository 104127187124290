exports.getPost = (url, data) => {
	return fetch(window.location.origin + url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data),
		credentials: 'include'
	})
}
