import React from 'react'
import { useSetNavBarTitle } from '../hooks/nav'
import UploadButton from './ui/upload-button'

function Files () {
	useSetNavBarTitle('Files')
	const [file, setFile] = React.useState('')

	let onUpload = (result) => {
		console.log(result)
		setFile(result.data)
	}

	return (
		<div>
			<UploadButton onUpload={onUpload} />
			<br />
			<span>{file}</span>
		</div>
	)
}

export default Files
