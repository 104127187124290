import React from 'react'
import { useSetNavBarTitle } from '../../../../hooks/nav'

function RmEmployees () {
	useSetNavBarTitle('Employees')

	return (
		<div>
		</div>
	)
}

export default RmEmployees
