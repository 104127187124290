import React from 'react'
import { useSetNavBarTitle } from '../hooks/nav'

function Dashboard () {
	useSetNavBarTitle('Dashboard')
	return (
		<div>Dashboard</div>
	)
}

export default Dashboard
