import { wsSend } from './websocket'

export const updateAccounts = (accounts) => {
	return {
		type: 'UPDATE_ACCOUNTS',
		accounts: accounts
	}
}

export const updatePersonnel = (personnel) => {
	return {
		type: 'UPDATE_PERSONNEL',
		personnel: personnel
	}
}

export const replaceData = (path, data) => {
	return {
		type: 'REPLACE_DATA',
		path: path,
		data: data
	}
}

export const updateDoc = (collection, _id, doc) => {
	return wsSend({
		type: 'update_doc',
		coll: collection,
		_id: _id,
		doc
	})
}

export const insertDoc = (collection, doc) => {
	return wsSend({
		type: 'insert_doc',
		coll: collection,
		doc
	})
}

export const upsertDoc = (collection, doc) => {
	return wsSend({
		type: 'upsert_doc',
		coll: collection,
		doc
	})
}

export const pushLog = (log) => {
	return {
		type: 'PUSH_LOG',
		log: log
	}
}

export const outputToConsole = (string) => {
	return {
		type: 'OUTPUT_TO_CONSOLE',
		string: string
	}
}

export const uploadFile = (data) => {
	return async dispatch => {
		try {

		} catch (e) {

		}
	}
}
