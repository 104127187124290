import { useState, Fragment } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/AddRounded'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import IconButton from '@material-ui/core/IconButton'
import { useSetNavBarTitle } from '../../hooks/nav'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		spacing: theme.spacing(3)
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(3),
		right: theme.spacing(3)
	}
}))

function sortItems(items) {
	return items.sort((a, b) => {
		if (a.name > b.name) return 1
		if (a.name < b.name) return -1
		if (a.name === b.name) return 0
	})
}

function InventoryItems () {
	useSetNavBarTitle('Inventory Items')
	const classes = useStyles()
	const history = useHistory()
	const unsortedItems = useSelector(state => state.data['inventory/items'])

	if(!unsortedItems) return <div>Loading...</div>

	const items = sortItems(unsortedItems)

	function handleNewItemClick(e) {
		history.push('/app/inventory/items/new')
	}

	function handleItemClick(e, item) {
		history.push('/app/inventory/items/' + item._id)
	}

	return (
		<div className={classes.root}>
			<Table className={classes.table} props={{ stickyHeader: true }}>
				<TableHead>
					<TableRow>
						<TableCell width={50}></TableCell>
						<TableCell>Item</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map(item => (
						<Row
							key={ item._id }
							item={ item }
							onClick={ event => handleItemClick(event, item) }
						/>
					))}
				</TableBody>
			</Table>
			<Fab className={classes.fab} onClick={e => handleNewItemClick(e)} color="primary">
				<AddIcon />
			</Fab>
		</div>
	)
}

function Row({ item, onClick }) {
	const [ open, setOpen ] = useState(false)

	return (
		<Fragment>
			<TableRow
				hover
				roll="checkbox"
				key={item._id}
				onClick={ onClick }
			>
					<TableCell>
						<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
							{open? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell>{item.name}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Paper style={{padding: 15, margin: 20}} elevation={1}>
						</Paper>
					</Collapse>
				</TableCell>
			</TableRow>
		</Fragment>
	)
}

export default InventoryItems
