import React from 'react'
import { connect } from 'react-redux'
import { useSetNavBarTitle } from '../hooks/nav'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	}
}))

function Console ({ consoleData, logs }) {
	useSetNavBarTitle('Console')
	
	const classes = useStyles()
	let key = 0
	return (
		<div className={classes.root}>
		{consoleData.map(string => {
			key++
			return (
				<p key={key}>{string}</p>
			)
		})}
		{logs.map(log => {
			key++
			return (
				<p key={key}>{log.msg}</p>
			)
		})}
		</div>
	)
}

const mapStateToProps = state => ({
	consoleData: state.data.console,
	logs: state.data.logs
})

export default connect(mapStateToProps)(Console)
