import { getPost } from '../utils/server-utils'
import store from '../store'
import history from '../history'
import { wsUnsubscribeAll, wsSubscribeToPermitted } from './websocket'

export const logIn = (email, password) => {
	return async dispatch => {
		try {
			let res = await getPost('/api/v1/authenticate', {
				email: email,
				password: password
			})
			let json = await res.json()
			if(json.authenticated){
				dispatch({
					type: 'LOG_IN',
					user: json.user,
					permissions: json.permissions
				})
				dispatch(wsSubscribeToPermitted(json.permissions, json.user))
				if(json.permissions.unemployement) {
					history.replace('/app/ui')
				} else {
					history.replace('/app/dashboard')
				}
			} else {
				dispatch({
					type: 'LOG_IN_FAILED'
				})
			}
		} catch (e) {
			console.log(e)
			dispatch(logOut())
		}
	}
}

export const logOut = () => {
	return dispatch => {
		getPost('/api/v1/unauthenticate')
		dispatch(wsUnsubscribeAll(store.getState().ws.subscriptions))
		dispatch({
			type: 'LOG_OUT'
		})
	}
}

export const checkCredentials = (path) => {
	return async dispatch => {
		try {
			dispatch({
				type: 'CHECKING_CREDENTIALS'
			})
			let res = await getPost('/api/v1/authenticate', {})
			let json = await res.json()
			if(json.authenticated) {
				dispatch({
					type: 'LOG_IN',
					user: json.user,
					permissions: json.permissions
				})
				dispatch(wsSubscribeToPermitted(json.permissions, json.user))
				if(json.permissions.unemployement) {
					history.replace('/app/ui')
				} else {
					history.replace(path)
				}
			} else {
				dispatch(logOut())
			}
		} catch (e) {
			console.log(e)
			dispatch(logOut())
		}
	}
}

export const signUp = (firstName, lastName, email, password) => {
	return async dispatch => {
		try {
			let res = await getPost('/api/v1/signup', {
				firstName: firstName,
				lastName: lastName,
				email: email,
				password: password
			})
			let json = await res.json()
			if(json.authenticated){
				history.replace('/login')
			} else {
			}
		} catch (e) {
		}
	}
}
